.plr-header {
    background: #344049;
    color: #FFFFFF;
    display: flex
}

.plr-header-wrapper,
.plr-header-left {
    display: flex;
    flex-direction: row;
    flex: 1
}

.plr-header-left {
    flex: 1;
}

.plr-time-now {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    justify-content: space-between;
}

.plr-header-mode-description {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

.plr-header-mode-name,
.plr-header-mode-location,
.plr-time-now-label,
.plr-time-now-time {
    font-weight: 500;
}



.plr-departure-list-item {
    display: flex;
    flex-direction: row;
    align-items: center;
}

.plr-departure-list-item-destination {
    flex: 1;
    min-width: 0;
    flex-shrink: 0;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow-x: clip
}

.plr-departure-list-item-route,
.plr-departure-list-item-destination {
    font-weight: 500;
}

.plr-departure-list-item-route {
    display: flex;
    align-items: center;
    justify-content: center;
}

.plr-departure-list-item-time {
    font-weight: 600;
}

.plr-departure-list-header {
    display: flex;
    flex-direction: row;
    align-items: flex-end;

    font-weight: 500
}

.plr-departure-list-header-next-service {
    flex: 1
}